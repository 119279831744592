<template>
  <v-container id="styles" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="custom-card-cont">
              <v-row class="style-search-bar">
                <v-col cols="12" class="text-field-cont">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    @input="debounceSearch"
                  />
                  <v-select
                    v-model="verificationFilter"
                    :items="verificationFilterOptions"
                    label="Filter Verification"
                  ></v-select>
                  <v-btn
                    class="ma-2 mt-3 color_class"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                  </v-btn>
                </v-col>
              </v-row>
            </md-card-content>
          </form>
          <v-row class="inquiry-list" v-if="!loading">
            <v-col
              cols="12"
              v-for="inquiry in inquiries"
              :key="inquiry.style_number"
              class="inquiry"
            >
              <div v-if="isSuperAdmin">
                <verify-inquiry-item
                  :inquiry="inquiry"
                  :isSuperAdmin="isSuperAdmin"
                  :baseUrl="baseUrl"
                  @toggle-verification="toggleVerificationStatus"
                />
              </div>
              <div v-else>
                <router-link :to="'/app/inquiry/' + inquiry.id">
                  <verify-inquiry-item
                    :inquiry="inquiry"
                    :isSuperAdmin="isSuperAdmin"
                    :baseUrl="baseUrl"
                    @toggle-verification="toggleVerificationStatus"
                  />
                </router-link>
              </div>
            </v-col>
            <v-col cols="12" align="right">
              <div style="margin-right: -8px">
                <Pagination
                  :current-page="currentPage"
                  :total-count="totalCount"
                  @load-page="loadPage"
                  v-if="inquiries.length"
                />
              </div>
            </v-col>
          </v-row>
          <div v-if="loading" class="my-5">
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>
          <v-row v-if="!loading && inquiries.length === 0">
            <v-col cols="12"> No verifiable styles found. </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VerifyInquiryItem from "../../components/Inquiry/VerifyInquiryItem.vue";
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";
import auth from "../../auth";
import * as conf from "../../conf.yml";

export default {
  name: "VerifyInquiries",
  components: {
    Pagination,
    VerifyInquiryItem,
  },
  data() {
    return {
      baseUrl: "",
      currentPage: 1,
      totalCount: 0,
      selected: [],
      search: "",
      styleFilter: null,
      verificationFilter: null,
      loading: false,
      inquiries: [],
      selectedHeader: "",
      styleFilterOptions: [
        { text: "Brand", value: "brand" },
        { text: "Supplier", value: "supplier" },
      ],
      verificationFilterOptions: [
        { text: "Verified", value: "true" },
        { text: "Unverified", value: "false" },
      ],
    };
  },
  mounted() {
    this.baseUrl = conf.default.server.host;
    this.fetchInquiries();
  },
  computed: {
    isSuperAdmin() {
      return auth.user?.role?.name === "super_admin";
    },
  },
  methods: {
    async fetchInquiries() {
      this.loading = true;

      let query = `?page=${this.currentPage}`;

      if (this.verificationFilter !== null) {
        query += `&is_verified=${this.verificationFilter}`;
      }

      if (this.search) {
        query += `&query=${this.search}`;
      }

      const response = await restAdapter.get(
        `/api/inquiries/${
          this.isSuperAdmin ? "verification_requests" : "verifiable"
        }` + query
      );

      if (response.data) {
        this.inquiries = response.data.data;
        this.totalCount = response.data.total;
      }
      this.loading = false;
    },
    toggleVerificationStatus(inquiry) {
      restAdapter
        .put(
          `/api/inquiries/${inquiry.id}/${
            this.isSuperAdmin ? "approve_request" : "verify"
          }`,
          {
            status: inquiry.verified,
          }
        )
        .then((res) => {
          notification.success(`Verification status updated successfully`);
        });
    },
    debounceSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchInquiries();
    }, 200),
    onFiltersChange() {
      this.currentPage = 1;
      this.fetchInquiries();
    },
    loadPage(page) {
      this.currentPage = page;
      this.fetchInquiries();
    },
    clearFilters() {
      this.styleFilter = null;
      this.verificationFilter = null;
      this.search = "";
      this.fetchInquiries();
    },
  },
  watch: {
    styleFilter: "onFiltersChange",
    verificationFilter: "onFiltersChange",
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  overflow-wrap: anywhere;
}
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.style-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style-search-bar {
  background: #ffffff;
  border-radius: 7px;
  margin: 0px 1px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.style-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
.inquiry-list {
  .md-title,
  .md-subhead {
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: #f3f1f1;
  }

  .inquiry-quantity {
    text-align: left;
  }

  .inquiry-colors {
    text-align: left;
    padding-top: 5px;
  }

  .inquiry-quantity__value {
    font-size: 15px;
    font-weight: bold;
    color: #442d65;
  }

  .inquiry-sub-title {
    font-size: 15px;
    color: #442d65;
  }

  .md-card-actions.md-alignment-right {
    justify-content: space-between;
  }
}

.grid-2 {
  display: flex;
  flex-direction: row;
}

.inquiry-search-bar {
  text-align: right;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search-content {
  margin-bottom: 40px;
  padding: 12px;
}

.search_field_class {
  margin: 0%;
}

.inquiry-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.header {
  background: #473068;
  height: calc(100% - 50%);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.inquiry-product-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.paddings {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.card-content {
  padding-bottom: 16px !important;
}

.embed {
  height: 50px;
}

.seperator {
  border-right: 1px solid #78909c;
}

.alignment {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .seperator {
    border-right: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .seperator {
    border-right: none;
    border-right: 10px solid #78909c;
  }
}

.card-content-item {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.centered-and-cropped {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65;
}

.color_class {
  color: #492a67 !important;
}

.qty_color {
  background-color: #c6a8ec !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #473068 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #473068 !important;
}

.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}

.search_type_container {
  margin-top: -10px !important;
  background-color: #f3edf7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.md-select-menu {
  top: 100px;
  border-radius: 64px;
  border: 10px solid;
}

.list_color.md-theme-default.md-selected {
  background-color: #e2daed !important;
}

.img-container {
  align-items: center;
}

.verification-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #64d5ca;
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
}

.verification-btn::v-deep {
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
.v-input--switch {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
